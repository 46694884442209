<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div v-if="loadingState === 'loading'" class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $auth.user.value.email }}
      </h3>
      <div class="flex justify-center">
        <h3 class="bg-yellow-50 p-4 my-12 max-w-lg rounded">
          <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Loading...
        </h3>
      </div>
    </div>

    <div v-else-if="loadingState === 'error'" class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $auth.user.value.email }}
      </h3>
      <div class="flex justify-center">
        <h3 class="bg-red-100 p-4 my-12 max-w-lg rounded">
          We ran into an internal error :(
        </h3>
      </div>
    </div>

    <div v-else>
      <TransitionRoot as="template" :show="openChangePw">
        <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="openChangePw = false" :open="openChangePw">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                      Change Password
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Simply logout and click the "forgot password" button on the login screen.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm" @click="openChangePw = false">
                    Cool!
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <TransitionRoot as="template" :show="openCloseAccount">
        <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="openCloseAccount = false" :open="openCloseAccount">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                      Close account
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Are you sure you want to close your account?<br>
                        If so, please <a href="https://blockbase.dev/contact" class="text-indigo-700 font-medium" target="_blank">contact us</a> and we will get it done right away.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <a href="https://blockbase.dev/contact" target="_blank" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="openCloseAccount = false">
                    Deactivate
                  </a>
                  <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="openCloseAccount = false" ref="cancelButtonRef">
                    Cancel
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $auth.user.value.email }}
          <button @click="openChangePw = true" type="button" class="float-right inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Change Password
          </button>
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500" v-if="$auth.user.value.name !== $auth.user.value.email">
          {{ $auth.user.value.name }}
        </p>
      </div>

      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              <div class="">
                Plan
              </div>
            </dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span class="flex-grow">
                <div class="">
                  {{ $auth.user.value.plan.name }}
                </div>
                <div class="">
                  {{ $auth.user.value.plan.message_quota }} emails/month
                </div>
                <div class="">
                  {{ $auth.user.value.plan.address_quota }} address/DB links
                </div>
              </span>
              <span class="ml-4 flex-shrink-0">
                <button v-if="$auth.user.value.plan.name === 'Free'" @click="startUpgrade" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Upgrade plan
                </button>
                <button v-else @click="doPortal" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Change plan
                </button>
              </span>
            </dd>

          </div>
        </dl>
      </div>

      <div class="border-t border-gray-200 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-red-500" @click="showDangerZone=true">
          Danger Zone
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <ul
            v-if="showDangerZone"
            class="border border-gray-200 rounded-md divide-y divide-gray-200"
            >
            <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div class="w-0 flex-1 flex items-center">
                <span class="ml-2 flex-1 w-0 truncate">
                  Close Blockbase Account
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <button @click="openCloseAccount = true"
                  class="font-medium text-red-600 hover:text-red-500">
                  Goodbye!
                </button>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </div>

    <Toast
      v-if="stripeSuccess"
      :ok="true"
      msg="Your plan has been upgraded!"
      msg_sub="Enjoy your new features :)"
    />
    <Toast
      v-if="stripeCancel"
      :ok="false"
      msg="Plan upgrade cancelled..."
      msg_sub="...you can still upgrade at any time."
    />

  </div>
</template>

<script>
// import { loadStripe } from '@stripe/stripe-js'
import { ExclamationIcon } from '@heroicons/vue/solid'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Toast from '@/components/Toast'
import * as Sentry from '@sentry/browser'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    Toast,
  },
  data () {
    return {
      showDangerZone: false,
      openChangePw: false,
      openCloseAccount: false,
      openChangePlan: false,
      stripeSuccess: false,
      stripeCancel: false,
      loadingState: 'loading',
    }
  },
  async mounted () {
    if (window.location.search.includes('upgrade_cancel')) {
      this.stripeCancel = true
    } else if (window.location.search.includes('upgrade_success')) {
      this.loadingState = 'loading'
      try {
        await this.$auth.updateAPITokenPlan()
      } catch (err) {
        this.loadingState = 'error'
        Sentry.captureException(new Error(`Profile updateAPITokenPlan: ${err}`))
        return
      }
      this.stripeSuccess = true
    }
    this.loadingState = 'ready'
  },
  methods: {
    async startUpgrade() {
      this.$router.push({ path: '/upgrade' })
    },
    async doPortal() {
      this.loadingState = 'loading'
      var portalurl = null
      try {
        const portalresult = await this.axios.get(process.env.VUE_APP_API_URL + 'portal',
          { headers: { authorization: 'Bearer ' + this.$auth.token.value } }
        )
        portalurl = portalresult.data.url
      } catch (err) {
        this.loadingState = 'error'
        Sentry.captureException(new Error(`Profile portal GET: ${err}`))
        return
      }
      document.location = portalurl
    },
  }
}
</script>
